import React from "react";
import { FiBookOpen, FiUser, FiArrowUpRight } from "react-icons/fi";

export const StepChart = () => {
  return (
    <div className="bg-neutral-900 p-4 text-neutral-50 md:p-12">
      <div className="mx-auto grid max-w-5xl grid-cols-1 divide-y divide-neutral-700 border border-neutral-700 md:grid-cols-3 md:divide-x md:divide-y-0">
        <TitleCard />
        <Card
          title="We will create three tailored CVs based on your top job preferences, each achieving a minimum ATS score of 80/100."
          stepDescription="Step 1: CV Optimization"
          imageSrc="https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?q=80&w=2264&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
        <Card
          title="Our consultants will enhance your LinkedIn profile to maximize its appeal to recruiters and industry professionals."
          stepDescription="Step 2: LinkedIn Enhancement"
          imageSrc="https://images.unsplash.com/photo-1574169208507-84376144848b?q=80&w=2379&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </div>
      <div className="mx-auto grid max-w-5xl grid-cols-1 divide-y divide-neutral-700 border-x border-b border-neutral-700 md:grid-cols-3 md:divide-x md:divide-y-0">
        <Card
          title="Our team will identify suitable visa-sponsored positions using our in-house Visa Sponsored Job Board and submit applications on your behalf every week. "
          stepDescription="Step 3: Targeted Job Search"
          imageSrc="https://images.unsplash.com/photo-1563089145-599997674d42?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          link="https://jobs.ukworkx.com"
          linkText="Click here to access the job board"
        />
        <Card
          title="We'll conduct mock interviews and provide detailed feedback to ensure you're fully prepared for the actual interviews."
          stepDescription="Step 4: Interview Preparation"
          imageSrc="https://images.unsplash.com/photo-1558591710-4b4a1ae0f04d?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
        <Card
          title="Congratulations! You've successfully secured a position in the UK with Tier 2 Visa Sponsorship."
          stepDescription="Step 5: Job Offer Acceptance"
          imageSrc="https://images.unsplash.com/photo-1604076913837-52ab5629fba9?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </div>
    </div>
  );
};

const Card = ({ title, stepDescription, imageSrc, link, linkText }) => {
  return (
    <div className="group relative flex h-56 flex-col justify-end overflow-hidden p-6 transition-colors hover:bg-neutral-950 md:h-80 md:p-9">
      <div className="absolute left-3 top-5 z-10 flex items-center gap-1.5 text-xs sm:text-lg text-neutral-400 font-medium uppercase transition-colors duration-500 group-hover:text-neutral-50">
        <FiUser className="text-base" />
        <span>{stepDescription}</span>
      </div>
      <h2 className="relative z-10 text-md sm:text-xl leading-tight transition-transform duration-500 group-hover:-translate-y-3">
        {title}
      </h2>
      {link && linkText && (
        <a
          href={link}
          className="relative z-10 mt-4 text-xs text-emerald-300 hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
        </a>
      )}
      <div
        className="absolute bottom-0 left-0 right-0 top-0 opacity-0 blur-sm grayscale transition-all group-hover:opacity-10 group-active:scale-105 group-active:opacity-30 group-active:blur-0 group-active:grayscale-0"
        style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Corners />
    </div>
  );
};

const Corners = () => (
  <>
    {[
      "left-[1px] top-[1px] h-3 w-[1px] origin-top",
      "left-[1px] top-[1px] h-[1px] w-3 origin-left",
      "bottom-[1px] right-[1px] h-3 w-[1px] origin-bottom",
      "bottom-[1px] right-[1px] h-[1px] w-3 origin-right",
      "bottom-[1px] left-[1px] h-3 w-[1px] origin-bottom",
      "bottom-[1px] left-[1px] h-[1px] w-3 origin-left",
      "right-[1px] top-[1px] h-3 w-[1px] origin-top",
      "right-[1px] top-[1px] h-[1px] w-3 origin-right",
    ].map((className, index) => (
      <span
        key={index}
        className={`absolute z-10 scale-0 bg-emerald-300 transition-all duration-500 group-hover:scale-100 ${className}`}
      />
    ))}
  </>
);

const TitleCard = () => {
  return (
    <div className="group relative flex h-56 flex-col justify-between bg-neutral-950 p-6 md:h-80 md:p-9">
      <h2 className="text-2xl sm:text-3xl uppercase leading-tight">
        <span className="text-neutral-400 transition-colors duration-500 group-hover:text-emerald-300">
          How
        </span>
        <br />
        we assist you in securing a sponsored position in the UK
      </h2>
      <div className="flex items-center gap-1.5 text-xs uppercase text-neutral-400 transition-colors duration-500 group-hover:text-neutral-50 pt-2">
        <FiBookOpen className="text-base" />
        <span>Note: We maintain a 93% success rate</span>
      </div>
      <FiArrowUpRight className="absolute right-3 top-4 text-2xl text-neutral-400 transition-colors duration-500 group-hover:text-emerald-300" />
    </div>
  );
};