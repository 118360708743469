import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Play } from 'lucide-react';

// Import your video files
import video from "../../videos/reviewvid1.mp4";
import videochetan from "../../videos/chetan.mp4";
import videoshweta from "../../videos/shweta.mp4";
import videosonal from "../../videos/sonalrawat.mp4";
import videosoniya from "../../videos/soniya.mp4";

const testimonials = [
  { name: "Dharmin", video: video },
  { name: "Chetan", video: videochetan },
  { name: "Sonal", video: videosonal },
  { name: "Soniya", video: videosoniya },
  { name: "Shweta", video: videoshweta },
];

function VideoTestimonial({ testimonial }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const handleEnded = () => setIsPlaying(false);
    const video = videoRef.current;
    
    if (video) {
      video.addEventListener('ended', handleEnded);
    }
    
    return () => {
      if (video) {
        video.removeEventListener('ended', handleEnded);
      }
    };
  }, []);

  return (
    <div className="flex-shrink-0 w-48 sm:w-56 md:w-64 mx-2"> {/* Adjusted width here */}
      <div className="relative">
        <video 
          ref={videoRef}
          className="w-full h-auto object-cover rounded-lg shadow-xl" 
          playsInline
          preload="metadata"
        >
          <source src={testimonial.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div 
          className="absolute inset-0 flex items-center justify-center cursor-pointer"
          onClick={togglePlayPause}
        >
          {!isPlaying && (
            <div className="bg-black bg-opacity-50 rounded-full p-3"> {/* Slightly reduced padding */}
              <Play className="h-6 w-6 text-white" /> {/* Reduced icon size */}
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 text-center"> {/* Reduced top margin */}
        <h3 className="text-sm sm:text-base font-medium text-gray-900"> {/* Adjusted text size */}
          Meet {testimonial.name}, Ex-Client of UKworkx
        </h3>
      </div>
    </div>
  );
}

function VideoTestimonials() {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll-fast
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('mousedown', handleMouseDown);
      scrollContainer.addEventListener('mouseup', handleMouseUp);
      scrollContainer.addEventListener('mousemove', handleMouseMove);
      scrollContainer.addEventListener('mouseleave', handleMouseUp);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('mousedown', handleMouseDown);
        scrollContainer.removeEventListener('mouseup', handleMouseUp);
        scrollContainer.removeEventListener('mousemove', handleMouseMove);
        scrollContainer.removeEventListener('mouseleave', handleMouseUp);
      }
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div className="w-full max-w-5xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8"> {/* Reduced vertical padding */}
      <div className="text-center mb-6"> {/* Reduced bottom margin */}
        <h2 className="text-xl sm:text-2xl font-bold text-gray-900"> {/* Adjusted text size */}
          Our Client Testimonials
        </h2>
      </div>

      <div 
        ref={scrollContainerRef}
        className="flex overflow-x-auto pb-4 space-x-4 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 cursor-grab active:cursor-grabbing"
        style={{ 
          scrollSnapType: 'x mandatory', 
          WebkitOverflowScrolling: 'touch',
          overscrollBehaviorX: 'contain'
        }}
      >
        {testimonials.map((testimonial, index) => (
          <div key={index} style={{ scrollSnapAlign: 'start' }}>
            <VideoTestimonial testimonial={testimonial} />
          </div>
        ))}
      </div>

      <div className="mt-6 text-center"> {/* Reduced top margin */}
        <Link 
          to="/casestudies" 
          className="text-base sm:text-lg font-medium text-indigo-600 hover:text-indigo-500" 
        >
          View more detailed case studies
        </Link>
      </div>
    </div>
  );
}

export default VideoTestimonials;