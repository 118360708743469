import React from 'react';
import { Star } from 'lucide-react';
import image3 from "../../images/heroimages/p8.jpeg"

const testimonial = {
  id: 1,
  stars: 5,
  quote: "As a Brazilian architect with a dream of working in the UK, I faced numerous challenges in navigating the job market and visa requirements. UKWorkx's expertise was invaluable. Their team not only helped me tailor my portfolio for the UK market but also connected me with firms offering sponsorship. Thanks to their guidance, I secured a position at a prestigious architectural firm in London. UKWorkx turned my dream into reality!",
  author: 'Elena',
  jobTitle: 'Junior Architect, London',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/elena-santos-architect/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Elena, a Brazilian national, completed her Masters in Sustainable Architecture at University College London in July 2024. With a passion for innovative, eco-friendly design, she aimed to start her career in the UK's competitive architecture sector while navigating the complex visa sponsorship process."
  },
  {
    title: "Challenges",
    content: [
      "Highly competitive UK architecture job market",
      "Need for a role offering Skilled Worker visa sponsorship",
      "Adapting her portfolio to UK architectural standards and expectations",
      "Limited professional network in the UK architecture community",
      "Balancing job search with completing her final Masters project",
      "Language barrier in professional architectural terminology"
    ]
  },
  {
    title: "Solution",
    content: [
      "Comprehensive portfolio review and optimization for the UK market",
      "Targeted job search focusing on architecture firms with sponsorship licenses",
      "Interview preparation tailored to UK architectural practices",
      "Networking strategies within the UK architecture community",
      "Guidance on visa sponsorship and UK architectural regulations",
      "English language coaching for architecture-specific terminology"
    ],
    intro: "In May 2024, Elena enrolled in our UKWorkx Creative Industries package, designed for international graduates in creative fields. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted a thorough review and adaptation of Elena's architectural portfolio",
      "Researched UK-based architecture firms with a history of sponsoring international talent",
      "Provided intensive interview preparation, including mock design presentations",
      "Arranged networking opportunities at UK architecture events and forums",
      "Offered guidance on UK building regulations and sustainable design practices",
      "Provided language coaching focused on architectural terminology and presentation skills"
    ],
    intro: "Our team worked closely with Elena to:"
  },
  {
    title: "Results",
    content: [
      "Elena secured a Junior Architect position in London in August 2024",
      "The role came with full Skilled Worker visa sponsorship",
      "She received positive feedback on her portfolio from multiple firms",
      "Successfully transitioned from her student visa to a Skilled Worker visa",
      "Started her UK architecture career within 3 months of graduation",
      "Gained a position that aligns with her passion for sustainable design"
    ],
    intro: "Through our specialized approach and Elena's dedication:"
  }
];

function CaseStudyElena() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Success Story: From International Architecture Graduate to Sponsored Junior Architect in London
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image3}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyElena;