import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Success Rate (%)',
    UKworkx: 93,
    'Other Consultants': 40,
    'Without Help': 10,
  },
  {
    name: 'Client Satisfaction(%)',
    UKworkx: 95,
    'Other Consultants': 50,
    'Without Help': 10,
  },
];

const UKWorkXComparisonGraph = () => {
  return (
    <div className="w-full sm:h-[600px] h-[450px] p-2 sm:p-4 bg-gray-100">
      <h2 className="text-xl sm:text-2xl font-bold text-center mb-2 sm:mb-4 text-gray-800">UKworkx compared to other career consultants?</h2>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 10,
            left: 0,
            bottom: 60,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
          <XAxis 
            dataKey="name" 
            tick={{fill: '#333333', fontSize: 10}} 
            interval={0}
            angle={0}
            textAnchor="middle"
            height={60}
          />
          <YAxis 
            tick={{fill: '#333333', fontSize: 12}} 
            domain={[0, 100]}
            tickFormatter={(value) => `${value}%`}
          />
          <Tooltip 
            contentStyle={{backgroundColor: '#f8f8f8', border: '1px solid #d0d0d0'}}
            itemStyle={{color: '#333333'}}
          />
          <Legend 
            wrapperStyle={{paddingTop: '10px'}}
            iconSize={16}
            formatter={(value, entry, index) => <span className="text-xs sm:text-lg">{value}</span>}
          />
          <Bar dataKey="UKworkx" fill="#4f46e5" /> {/* indigo-600 */}
          <Bar dataKey="Other Consultants" fill="#666666" />
          <Bar dataKey="Without Help" fill="#cccccc" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UKWorkXComparisonGraph;