import React from 'react';
import { Star } from 'lucide-react';
import image3 from "../../images/heroimages/p12.jpeg"


const testimonial = {
  id: 1,
  stars: 5,
  quote: "As a Japanese biomedical researcher, I dreamed of contributing to the UK's innovative life sciences sector. However, the path seemed daunting, especially with the language barrier and complex visa requirements. UKWorkx's specialized guidance was a game-changer. They helped me showcase my research skills, navigate the sponsorship process, and connect with leading research institutions. Thanks to their support, I'm now part of a cutting-edge research team in Cambridge. UKWorkx truly bridged the gap between my aspirations and reality!",
  author: 'Yuki',
  jobTitle: 'Research Scientist, Cambridge',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/yuki-tanaka-biomedresearch/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Yuki, a Japanese national, completed her Masters in Biomedical Sciences at Imperial College London in September 2022. With a passion for groundbreaking medical research, she aimed to secure a position in the UK's prestigious life sciences sector while navigating the intricate visa sponsorship process."
  },
  {
    title: "Challenges",
    content: [
      "Highly specialized field with limited openings for international researchers",
      "Need for a role offering Skilled Worker visa sponsorship in academia or industry",
      "Adapting research experience to meet UK standards and expectations",
      "Language barrier in scientific writing and presentation",
      "Limited network in the UK life sciences community",
      "Balancing job search with completing and publishing Masters research"
    ]
  },
  {
    title: "Solution",
    content: [
      "Comprehensive review and optimization of research portfolio and academic CV",
      "Targeted search for research positions in both academia and industry",
      "Interview and presentation coaching for scientific audiences",
      "Networking strategies within the UK life sciences community",
      "Guidance on visa sponsorship for researchers and UK research ethics",
      "Scientific English language coaching and paper writing support"
    ],
    intro: "In July 2023, Yuki enrolled in our UKWorkx Scientific Careers package, tailored for international graduates in research fields. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted a thorough review of Yuki's research portfolio and publications",
      "Identified UK-based research institutions and biotech companies with sponsorship licenses",
      "Provided intensive preparation for research presentations and academic interviews",
      "Arranged introductions to key figures in UK biomedical research circles",
      "Offered guidance on UK research funding landscape and grant application processes",
      "Provided specialized coaching in scientific English and academic writing"
    ],
    intro: "Our team collaborated closely with Yuki to:"
  },
  {
    title: "Results",
    content: [
      "Yuki secured a Research Scientist position in Cambridge in December 2024",
      "The role came with full Skilled Worker visa sponsorship",
      "She received interest from both academic institutions and industry labs",
      "Successfully transitioned from her student visa to a Skilled Worker visa",
      "Started her UK research career within 3 months of graduation",
      "Gained a position that allows her to continue her Masters research topic"
    ],
    intro: "Through our specialized approach and Yuki's dedication:"
  }
];

function CaseStudyYuki() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Success Story: From International Biomedical Graduate to Sponsored Research Scientist in Cambridge
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image3}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyYuki;