import React from "react";
import { FiLinkedin } from "react-icons/fi";
import { SiMicrosoft, SiVodafone, SiMeta } from "react-icons/si";
import evelyn from "../../images/evelyn.png";
import lalit from "../../images/lalit.png";

const FounderCard = ({
  imgUrl,
  companyLogos,
  quote,
  name,
  linkedinUrl,
  position,
}) => {
  return (
    <div className="grid h-auto w-[350px] select-none place-content-center space-y-6 rounded-2xl border-2 border-slate-700 bg-slate-800/20 p-6 shadow-xl backdrop-blur-md">
      <img
        src={imgUrl}
        alt={`Image of ${name}`}
        className="pointer-events-none mx-auto h-32 w-32 rounded-full border-2 border-slate-700 bg-slate-200 object-cover"
      />
      <div className="flex flex-col items-center space-y-2">
        <span className="text-sm text-slate-400">From</span>
        <div className="flex justify-center space-x-2">
          {companyLogos.map((Logo, index) => (
            <Logo key={index} className="h-6 w-6 text-slate-400" />
          ))}
        </div>
      </div>
      <span className="text-center text-sm italic text-slate-400">
        "{quote}"
      </span>
      <span className="text-center text-sm font-medium text-indigo-400">
        {name}
      </span>
      <span className="text-center text-sm font-medium text-indigo-400">
        {position}
      </span>
      <a
        href={linkedinUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center text-indigo-400 hover:text-indigo-300 transition-colors duration-300"
      >
        <FiLinkedin size={16} className="mr-1" />
        <span className="text-sm">LinkedIn Profile</span>
      </a>
    </div>
  );
};

const MeetOurFounders = () => {
  const founders = [
    {
      imgUrl: evelyn,
      companyLogos: [SiMicrosoft, SiVodafone],
      quote: "My career at Vodafone and Microsoft led me to become a career consultant at a top Russell Group university. There, I helped international students land jobs through workshops, mock interviews, and more. As someone who moved to the UK myself, I understand the challenges. This blend of corporate and personal experience inspired me to create ukworkx, where I help others achieve their UK career goals.",
      name: "Evelyn - Ex Microsoft & Vodafone",
      linkedinUrl: "https://www.linkedin.com/in/evelyn-rose-bino/",
      position: "CEO & Founder",
    },
    {
      imgUrl: lalit,
      companyLogos: [SiMeta],
      quote:
        "As a Cardiff University MSc grad now working at Meta on a skilled worker visa, I've experienced the challenges international students face in the UK job market. Having successfully navigated this path, I'm here to guide others seeking visa-sponsored roles.",
      name: "Lalit - Software Engineer @ Meta",
      linkedinUrl: "https://www.linkedin.com/in/lalit-bawa/",
      position: "Technical Co-founder",
    },
  ];

  return (
    <div className="grid place-content-center overflow-hidden bg-slate-950 pt-8 py-24 text-slate-50">
      <h2 className="text-xl font-medium text-center mb-2 italic">
        Meet Our Founders
      </h2>
      <h3 className="text-xs text-center mb-6">
        Our Founders are Russel Group University Graduates Working at Top 100
        Companies
      </h3>
      <div className="flex justify-center flex-col md:flex-row gap-8 ">
        {founders.map((founder, index) => (
          <FounderCard key={index} {...founder} />
        ))}
      </div>
    </div>
  );
};

export default MeetOurFounders;
